.App {
    overflow: hidden;
  }
  
  .card {
    width: 200px !important;
    height: 200px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(158, 151, 151, 0.25);
    border-radius: 15px;
    margin: 12px;
    padding: 12px;
  }
  
  .d-flex {
    display: flex;
    transform: translateX(calc(100vw));
    overflow: visible;
    animation: animateContainer 10s linear forwards infinite;
    animation-play-state: paused;
  }
  
  @keyframes animateContainer {
    from {
      transform: translateX(calc(100vw));
    }
    to {
      transform: translateX(calc(-100%));
    }
  }  

  
.faqs {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 15px;
}

.faqs .faq {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom-width: 2px;
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
  font-weight: 500;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  color: #3E3E3E;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
